<template>
  <div v-if="active">
    <div
      :key="index"
      v-for="(company,index) in childCompanyList">
      <TradesChart
        v-if="company.basic&&company.trades"
        :id="`tradesChart_${index}`"
        :basic="company.basic"
        :trades="company.trades">
      </TradesChart>
      <ProcurementsChart
        v-if="company.basic&&company.procurements_sum_by_year"
        :id="`procurementsChart_${index}`"
        :basic="company.basic"
        :procurements_sum_by_year="company.procurements_sum_by_year">
      </ProcurementsChart>
      <RevenueHistoryChart
        v-if="company.basic&&company.revenue_history"
        :id="`revenueHistoryChart_${index}`"
        :basic="company.basic"
        :revenue_history="company.revenue_history">
      </RevenueHistoryChart>
    </div>
  </div>
</template>

<script>
import * as apis from '@/apis/index.ts'
import store from '@/store/index'
import utils from '@/utils/utils.js'
import * as codes from '@/const/codes.ts'
import pdfMake from 'pdfmake/build/pdfmake'
import moment from 'moment'
// import * as apiInterfaces from '@/interfaces/apiInterfaces.ts'
// import * as companyInterfaces from '@/interfaces/companyInterfaces.ts'
// import * as companysHistoryUtils from '@/utils/companysHistoryUtils.ts'
// import * as screenImageGetter from '@/utils/screenImageGetter.ts'
import TradesChart from '@/components/TradesChart/TradesChart.vue'
import ProcurementsChart from '@/components/ProcurementsChart/ProcurementsChart.vue'
import RevenueHistoryChart from '@/components/RevenueHistoryChart/RevenueHistoryChart.vue'
import * as pdfTemplates from '@/utils/pdfTemplates'

export default {
  props: {
    active: {
      type: Boolean,
      required: true
    },
    companyList: {
      required: true
    }
  },
  components: {
    TradesChart,
    ProcurementsChart,
    RevenueHistoryChart
  },
  data () {
    return {
      childCompanyList: []
      // basic: null,
      // trades: null,
      // procurements_sum_by_year: null,
      // revenue_history: null
    }
  },
  computed: {
    // isShowTradesChart () {
    //   return this.basic && this.trades && this.trades.list && this.trades.list.length
    // },
    // isShowProcurementsChart () {
    //   return this.basic && this.procurements_sum_by_year && Object.keys(this.procurements_sum_by_year).length
    // },
    // isShowRevenueHistoryChart () {
    //   return this.basic && this.revenue_history && this.revenue_history.list && this.revenue_history.list.length
    // }
  },
  methods: {
    async download () {
      try {
        // 等待繪製圖表
        await new Promise(resolve => setTimeout(() => resolve(), 1000))

        // -- 依權限建立pdfmake資料 --
        const companyList = this.childCompanyList
        const allCompanyNames = companyList.map(d => d.basic.company_name).join(', ')

        let baseTemplate = pdfTemplates.returnBaseTemplate({
          url: window.location.href,
          fontName: 'NotoSansTC'
        })

        baseTemplate.content = baseTemplate.content
          .concat(
            // 封面
            pdfTemplates.returnFrontCover({
              companyName: allCompanyNames
            })
          ).concat(
            // 章節大標
            pdfTemplates.returnChepterTitle({
              title: '比較結果',
              subtitle: allCompanyNames
            })
          ).concat(
            // 公司簡介 - 標題
            pdfTemplates.returnDescriptionTitle()
          )
        companyList.forEach(company => {
          baseTemplate.content = baseTemplate.content
            .concat(
              // 公司名稱
              pdfTemplates.returnSecondTitleSection(company.basic.company_name)
            ).concat(
              // 公司簡介
              pdfTemplates.returnDescription(company)
            )
        })
        
        baseTemplate.content = baseTemplate.content
          .concat(
            // 商工登記資料 - 標題
            pdfTemplates.returnBasicTableTitle(companyList)
          )
        companyList.forEach(company => {
          baseTemplate.content = baseTemplate.content
            .concat(
              // 公司名稱
              pdfTemplates.returnSecondTitleSection(company.basic.company_name)
            ).concat(
              // 商工登記資料
              pdfTemplates.returnBasicTable(company)
            )
        })
        
          
        
        if (store.getters.getterLimitD) {
          // searchRequest.get.shareholders = { 'page-size': 10000 }
          // searchRequest.get.managers = { 'page-size': 10000 }
          // searchRequest.get.companys_history = { 'page-size': 10000 }
          // searchRequest.get.company_branchs = { 'page-size': 10000 }
          // searchRequest.get.factorys = { 'page-size': 10000 }
          if (companyList[0].shareholders) {
            baseTemplate.content = baseTemplate.content.concat(
              // 董監事資料 - 標題
              pdfTemplates.returnShareholdersTableTitle(companyList)
            )
            companyList.forEach(company => {
              baseTemplate.content = baseTemplate.content
                .concat(
                  // 公司名稱
                  pdfTemplates.returnSecondTitleSection(company.basic.company_name)
                ).concat(
                  // 董監事資料
                  pdfTemplates.returnShareholdersTable(company)
                )
            })
          }
          if (companyList[0].managers) {
            baseTemplate.content = baseTemplate.content.concat(
              // 經理人資料 - 標題
              pdfTemplates.returnManagersTableTitle(companyList)
            )
            companyList.forEach(company => {
              baseTemplate.content = baseTemplate.content
                .concat(
                  // 公司名稱
                  pdfTemplates.returnSecondTitleSection(company.basic.company_name)
                ).concat(
                  // 經理人資料
                  pdfTemplates.returnManagersTable(company)
                )
            })
          }
          if (companyList[0].companys_history) {
            baseTemplate.content = baseTemplate.content.concat(
              // 變更紀錄 - 標題
              pdfTemplates.returnCompanysHistoryTableTitle(companyList)
            )
            companyList.forEach(company => {
              baseTemplate.content = baseTemplate.content
                .concat(
                  // 公司名稱
                  pdfTemplates.returnSecondTitleSection(company.basic.company_name)
                ).concat(
                  // 變更紀錄
                  pdfTemplates.returnCompanysHistoryTable(company)
                )
            })
          }
          if (companyList[0].company_branchs) {
            baseTemplate.content = baseTemplate.content.concat(
              // 分公司資料 - 標題
              pdfTemplates.returnCompanyBranchsTableTitle(companyList)
            )
            companyList.forEach(company => {
              baseTemplate.content = baseTemplate.content
                .concat(
                  // 公司名稱
                  pdfTemplates.returnSecondTitleSection(company.basic.company_name)
                ).concat(
                  // 分公司資料
                  pdfTemplates.returnCompanyBranchsTable(company)
                )
            })
          }
        }
        if (companyList[0].basic) {
          baseTemplate.content = baseTemplate.content
            .concat(
              // 國貿局「出進口廠商管理系統」 - 標題
              pdfTemplates.returnCompanyNameTableTitle(companyList)
            )
          companyList.forEach(company => {
            baseTemplate.content = baseTemplate.content
              .concat(
                // 公司名稱
                pdfTemplates.returnSecondTitleSection(company.basic.company_name)
              ).concat(
                // 國貿局「出進口廠商管理系統」
                pdfTemplates.returnCompanyNameTable(company)
              )
          })
        }
        if (companyList[0].company_operate) {
          baseTemplate.content = baseTemplate.content.concat(
            // 財政部稅務入口網-營業(稅籍)登記資料公示查詢網站 - 標題
            pdfTemplates.returnCompanyStateTableTitle(companyList)
          )
          companyList.forEach(company => {
            baseTemplate.content = baseTemplate.content
              .concat(
                // 公司名稱
                pdfTemplates.returnSecondTitleSection(company.basic.company_name)
              ).concat(
                // 財政部稅務入口網-營業(稅籍)登記資料公示查詢網站
                pdfTemplates.returnCompanyStateTable(company)
              )
          })
        }
        if (store.getters.getterLimitD) {
          if (companyList[0].factorys) {
            baseTemplate.content = baseTemplate.content.concat(
              // 工廠登記 - 標題
              pdfTemplates.returnFactorysTableTitle(companyList)
            )
            companyList.forEach(company => {
              baseTemplate.content = baseTemplate.content
                .concat(
                  // 公司名稱
                  pdfTemplates.returnSecondTitleSection(company.basic.company_name)
                ).concat(
                  // 工廠登記
                  pdfTemplates.returnFactorysTable(company)
                )
            })
          }
          if (companyList[0].登記地址近似公司) {
            baseTemplate.content = baseTemplate.content.concat(
              // 登記地址近似公司 - 標題
              pdfTemplates.returnSimilarAddressTitle(companyList)
            )
            companyList.forEach(company => {
              baseTemplate.content = baseTemplate.content
                .concat(
                  // 公司名稱
                  pdfTemplates.returnSecondTitleSection(company.basic.company_name)
                ).concat(
                  // 登記地址近似公司
                  pdfTemplates.returnSimilarAddress(company)
                )
            })
          }
        }
        
        // 風險指標
        if (store.getters.getterLimitE) {
          // searchRequest.get.verdicts = { 'page-size': 10000 }
          // searchRequest.get.news = {}
          // searchRequest.get.disputes = { 'page-size': 10000 }
          if (companyList[0].verdicts) {
            baseTemplate.content = baseTemplate.content.concat(
              // 法律訴訟 - 標題
              pdfTemplates.returnVerdictsTableTitle(companyList)
            )
            companyList.forEach(company => {
              baseTemplate.content = baseTemplate.content
                .concat(
                  // 公司名稱
                  pdfTemplates.returnSecondTitleSection(company.basic.company_name)
                ).concat(
                  // 法律訴訟
                  pdfTemplates.returnVerdictsTable(company)
                )
            })
          }
          if (companyList[0].news) {
            baseTemplate.content = baseTemplate.content.concat(
              // 新聞事件 - 標題
              pdfTemplates.returnNewsTableTitle(companyList)
            )
            companyList.forEach(company => {
              baseTemplate.content = baseTemplate.content
                .concat(
                  // 公司名稱
                  pdfTemplates.returnSecondTitleSection(company.basic.company_name)
                ).concat(
                  // 新聞事件
                  pdfTemplates.returnNewsTable(company)
                )
            })
          }
          if (companyList[0].disputes) {
            baseTemplate.content = baseTemplate.content.concat(
              // 勞資糾紛 - 標題
              pdfTemplates.returnDisputesTableTitle(companyList)
            )
            companyList.forEach(company => {
              baseTemplate.content = baseTemplate.content
                .concat(
                  // 公司名稱
                  pdfTemplates.returnSecondTitleSection(company.basic.company_name)
                ).concat(
                  // 勞資糾紛
                  pdfTemplates.returnDisputesTable(company)
                )
            })
          }
          if (companyList[0].procurement_bad_vendors) {
            baseTemplate.content = baseTemplate.content.concat(
              // 拒絕往來廠商紀錄 - 標題
              pdfTemplates.returnProcurementBadVendorsTableTitle(companyList)
            )
            companyList.forEach(company => {
              baseTemplate.content = baseTemplate.content
                .concat(
                  // 公司名稱
                  pdfTemplates.returnSecondTitleSection(company.basic.company_name)
                ).concat(
                  // 拒絕往來廠商紀錄
                  pdfTemplates.returnProcurementBadVendorsTable(company)
                )
            })
          }
        }
        // 經營指標
        if (store.getters.getterLimitF) {
          // searchRequest.get.trades = { 'page-size': 10000 }
          // searchRequest.get.procurements = { 'page-size': 10000 }
          // searchRequest.get.patents = { 'page-size': 10000 }
          // searchRequest.get.awards = { 'page-size': 10000 }
          
          if (companyList[0].trades) {
            baseTemplate.content = baseTemplate.content.concat(
              // 進出口實績 - 標題
              pdfTemplates.returnTradesTableTitle(companyList)
            )
            companyList.forEach(async (company,i) => {
              baseTemplate.content = baseTemplate.content
                .concat(
                  // 公司名稱
                  pdfTemplates.returnSecondTitleSection(company.basic.company_name)
                ).concat(
                  // 進出口實績
                  pdfTemplates.returnTradesTable(company)
                )
              // 進出口實績圖表
              if (company.basic && company.trades && company.trades.list && company.trades.list.length) {
                const tradesChart = await pdfTemplates.returnTradesChart(`#tradesChart_${i}`)
                baseTemplate.content = baseTemplate.content.concat(tradesChart)
              }
            })
          }
          
          if (companyList[0].procurements) {
            baseTemplate.content = baseTemplate.content.concat(
              // 政府標案 - 標題
              pdfTemplates.returnProcurementsTableTitle(companyList)
            )
            companyList.forEach(async (company,i) => {
              baseTemplate.content = baseTemplate.content
                .concat(
                  // 公司名稱
                  pdfTemplates.returnSecondTitleSection(company.basic.company_name)
                ).concat(
                  // 政府標案
                  pdfTemplates.returnProcurementsTable(company)
                )
                // 政府標案圖表
                if (company.basic && company.procurements_sum_by_year && Object.keys(company.procurements_sum_by_year).length) {
                  const procurementsChart = await pdfTemplates.returnProcurementsChart(`#procurementsChart_${i}`)
                  baseTemplate.content = baseTemplate.content.concat(procurementsChart)
                }
            })
          }

          if (companyList[0].job104s_jobs) {
            baseTemplate.content = baseTemplate.content.concat(
              // 人力招聘 - 標題
              pdfTemplates.returnJobsTableTitle(companyList)
            )
            companyList.forEach(async (company,i) => {
              baseTemplate.content = baseTemplate.content
                .concat(
                  // 公司名稱
                  pdfTemplates.returnSecondTitleSection(company.basic.company_name)
                ).concat(
                  // 人力招聘
                  pdfTemplates.returnJobsTable(company)
                )
            })
          }
          
          if (companyList[0].patents) {
            baseTemplate.content = baseTemplate.content.concat(
              // 專利資訊 - 標題
              pdfTemplates.returnPatentsTableTitle(companyList)
            )
            companyList.forEach((company,i) => {
              baseTemplate.content = baseTemplate.content
                .concat(
                  // 公司名稱
                  pdfTemplates.returnSecondTitleSection(company.basic.company_name)
                ).concat(
                  // 專利資訊
                  pdfTemplates.returnPatentsTable(company)
                )
            })
          }
          
          if (companyList[0].awards) {
            baseTemplate.content = baseTemplate.content.concat(
              // 得獎紀錄與政府推薦名單 - 標題
              pdfTemplates.returnAwardsTableTitle(companyList)
            )
            companyList.forEach((company,i) => {
              baseTemplate.content = baseTemplate.content
                .concat(
                  // 公司名稱
                  pdfTemplates.returnSecondTitleSection(company.basic.company_name)
                ).concat(
                  // 得獎紀錄與政府推薦名單
                  pdfTemplates.returnAwardsTable(company)
                )
            })
          }
        }
        // 財務指標
        if (store.getters.getterLimitG) {
          // searchRequest.get.revenue_history = { 'page-size': 10000 }
          // searchRequest.get.ifrss_summary = { 'page-size': 10000 }
          if (companyList[0].publics_news) {
            baseTemplate.content = baseTemplate.content.concat(
              // 營收資訊 - 標題
              pdfTemplates.returnPublicsNewsTableTitle(companyList)
            )
            companyList.forEach(async (company,i) => {
              baseTemplate.content = baseTemplate.content
                .concat(
                  // 公司名稱
                  pdfTemplates.returnSecondTitleSection(company.basic.company_name)
                ).concat(
                  // 營收資訊
                  pdfTemplates.returnPublicsNewsTable(company)
                )
            })
          }
          
          if (companyList[0].revenue_history) {
            baseTemplate.content = baseTemplate.content.concat(
              // 營收資訊 - 標題
              pdfTemplates.returnRevenueHistoryTableTitle(companyList)
            )
            companyList.forEach(async (company,i) => {
              baseTemplate.content = baseTemplate.content
                .concat(
                  // 公司名稱
                  pdfTemplates.returnSecondTitleSection(company.basic.company_name)
                ).concat(
                  // 營收資訊
                  pdfTemplates.returnRevenueHistoryTable(company)
                )
              // 歷年實績統計圖表
              if (company.basic && company.revenue_history && company.revenue_history.list && company.revenue_history.list.length) {
                const revenueHistoryChart = await pdfTemplates.returnRevenueHistoryChart(`#revenueHistoryChart_${i}`)
                baseTemplate.content = baseTemplate.content.concat(revenueHistoryChart)
              }
            })
          }
          
          if (companyList[0].ifrss_summary) {
            baseTemplate.content = baseTemplate.content.concat(
              // 採IFRSs後財報資訊 - 標題
              pdfTemplates.returnIfrssSummaryTableTitle(companyList)
            )
            companyList.forEach((company,i) => {
              baseTemplate.content = baseTemplate.content
                .concat(
                  // 公司名稱
                  pdfTemplates.returnSecondTitleSection(company.basic.company_name)
                ).concat(
                  // 採IFRSs後財報資訊
                  pdfTemplates.returnIfrssSummaryTable(company)
                )
            })
          }
        }
        // 封底
        baseTemplate.content = baseTemplate.content.concat(
          pdfTemplates.returnBackCover()
        )

        // -- 產生pdf --
        // pdfMake.vfs = await import('pdfmake/build/vfs_fonts')
        // pdfMake.vfs = await import('@/assets/fonts/vfs_fonts.js')
        pdfMake.vfs = await import('@/assets/fonts/vfs_NotoSansTC.js')

        pdfMake.fonts = {
          /*Roboto為默認字體*/  
          // Roboto: {
          //   normal: 'Roboto-Regular.ttf',
          //   bold: 'Roboto-Medium.ttf',
          //   italics: 'Roboto-Italic.ttf',
          //   bolditalics: 'Roboto-Italic.ttf' 
          // },
          /*加入我們自己的中文的字體*/
          // MSFont: {
          //   normal: 'MingLiU.ttf',
          //   bold: 'MingLiU.ttf',
          //   italics: 'MingLiU.ttf',
          //   bolditalics: 'MingLiU.ttf', 
          // },
          NotoSansTC: {
            normal: 'NotoSansTC-Regular.woff2',
            bold: 'NotoSansTC-Bold.woff2',
          }
        }

        // console.log(baseTemplate)
        // console.log(JSON.stringify(baseTemplate, null, 2))

        pdfMake.createPdf(baseTemplate).download(`${allCompanyNames}_${moment().format('YYYY-MM-DD')}`);

        this.$emit('update:active', false)
        // return Promise.resolve(baseTemplate)
      } catch (e) {
        console.error(e)
        this.$message.error('未知錯誤')
        // return Promise.reject(null)
        this.$emit('update:active', false)
      }
    }
  },
  watch: {
    active: {
      handler (d) {
        // if (d === true && this.company && this.company.basic) {
        //   // 取得必要資料
        //   let trades = null
        //   let revenue_history = null
        //   let procurements_sum_by_year = null
          
        //   this.basic = JSON.parse(JSON.stringify(this.company.basic))
        //   if (this.company.trades) {
        //     trades = JSON.parse(JSON.stringify(this.company.trades))
        //   }
        //   this.trades = trades
        //   if (this.company.revenue_history) {
        //     revenue_history = JSON.parse(JSON.stringify(this.company.revenue_history))
        //   }
        //   this.revenue_history = revenue_history
        //   if (this.company.basic.procurements_sum_by_year) {
        //     procurements_sum_by_year = JSON.parse(JSON.stringify(this.company.basic.procurements_sum_by_year))
        //   }
        //   this.procurements_sum_by_year = procurements_sum_by_year
          
        //   // 下載
        //   this.download()
        // }
        if (d === true && this.companyList && this.companyList.length) {
          this.childCompanyList = JSON.parse(JSON.stringify(this.companyList))
          // 下載
          this.download()
        }
        
      },
      immediate: true
    }
  }
}
</script>